import { Divider, Stack, Toolbar, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { useBreakpoint } from '../../lib/hooks';
import { useGetIotCloudVersionQuery } from '../../redux/api/iotCloud';
import { useGetProfileQuery } from '../../redux/api/montageApi';

const DebugAppBar = () => {
  const breakpoint = useBreakpoint();
  const { data: iotCloudVersion } = useGetIotCloudVersionQuery();
  const { data: profile } = useGetProfileQuery();
  return (
    <AppBar
      position="fixed"
      component="nav"
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
        backgroundColor: 'lightgray',
      }}
    >
      <Toolbar>
        <Stack>
          <Typography color={'black'}>Style</Typography>
          <Typography sx={{ fontWeight: 'bold' }} color={'black'}>
            {breakpoint}
          </Typography>
        </Stack>
        <Divider orientation="vertical" flexItem sx={{ ml: 1, mr: 1 }} />
        <Stack>
          <Stack direction="row">
            <Typography color={'black'}>User </Typography>
            <Typography sx={{ fontWeight: 'bold' }} color={'black'}>
              {profile?.id}
            </Typography>
          </Stack>
          <Typography color={'black'}>{profile?.email}</Typography>
        </Stack>
        <Divider orientation="vertical" flexItem sx={{ ml: 1, mr: 1 }} />
        <Stack>
          <Typography color={'black'}>IoT Cloud</Typography>
          <Typography color={'black'}>v{iotCloudVersion}</Typography>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default DebugAppBar;
