import { Stack, Box, CircularProgress, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PageAlert = ({
  severity,
  children,
  isLoading = false,
  translationKey = 'common.unknownError',
  withPadding,
}: {
  severity: 'error' | 'warning' | 'info' | 'success';
  children?: React.ReactNode;
  isLoading?: boolean;
  translationKey?: string;
  withPadding: boolean;
}) => {
  const { t } = useTranslation('translation');

  return (
    <Stack sx={{ width: '100%', px: 7, p: withPadding ? 5 : 0 }} gap={5}>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            p: 10,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flex: 1, pt: 2 }}>
          <Alert severity={severity}>{children || t(translationKey)}</Alert>
        </Box>
      )}
    </Stack>
  );
};
export default PageAlert;
