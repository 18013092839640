import {
  Box,
  CircularProgress,
  DialogContent,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/ex.svg';
import DialogFooter from '../../../../common/dialog/DialogFooter';

type ChangeAuth0UserIdDialogResultStageProps = {
  resultText: string | null;
  onClose: () => void;
};

const ChangeAuth0UserIdDialogResultStage = ({
  resultText,
  onClose,
}: ChangeAuth0UserIdDialogResultStageProps) => {
  const { t } = useTranslation('translation');

  return (
    <DialogContent>
      {resultText ? (
        <Typography variant="h6">{resultText}</Typography>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      <DialogFooter
        buttonsData={[
          {
            colorVariant: 'secondary',
            icon: <CloseIcon />,
            disabled: !resultText,
            label: t('common.close'),
            onClick: onClose,
          },
        ]}
      />
    </DialogContent>
  );
};

export default ChangeAuth0UserIdDialogResultStage;
