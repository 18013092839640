import { styled, alpha, Theme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import {
  useGetInstallationDeletionsQuery,
  useGetIotCloudSearchQuery,
} from '../../redux/api/iotCloud';
import { getFailedAndNotRetriedInstallationDeletion } from './MainPage/InstallationDeletionCard';
import { isNil } from 'lodash';
import { skipToken } from '@reduxjs/toolkit/query';
import { GlobalParamEnum, useGlobalParam, usePrevious } from '../../lib/hooks';
import { SubmittedParamExposeType } from '../../redux/servicePortal';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const PrimarySearchAppBar = ({
  title,
  onToggleDrawer,
  prevToolbarCount = 0,
}: {
  title?: string;
  onToggleDrawer: () => void;
  prevToolbarCount?: number;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [
    globalSearch,
    submittedGlobalSearch,
    setGlobalSearch,
    submitGlobalSearch,
  ] = useGlobalParam(GlobalParamEnum.GlobalSearch);
  const [, , , submitSearch] = useGlobalParam(GlobalParamEnum.Search, {
    expose: SubmittedParamExposeType.URL,
  });

  const { data, isSuccess, isFetching } = useGetIotCloudSearchQuery(
    submittedGlobalSearch
      ? {
          phrase: `${submittedGlobalSearch}`,
          loadMore: 0,
          lazyResults: true,
          skipAfterSpecific: true,
          onlySpecific: true,
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    },
  );

  const { data: installationDeletionData } = useGetInstallationDeletionsQuery();
  const notificationCount = getFailedAndNotRetriedInstallationDeletion(
    installationDeletionData,
  ).length;

  const isAccountMenuOpen = Boolean(anchorEl);
  const onAccountMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onAccountMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOnAccountButton = () => {
    console.log('on account');
  };
  const handleOnLogout = () => {
    console.log('on logout');
  };

  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      setGlobalSearch(value);
    },
    [setGlobalSearch],
  );

  const onSearchInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        const lowerCaseGlobalSearch = `${globalSearch}`.toLowerCase();
        let navigateTo: string | undefined;

        if (
          ['dashboard', 'deletions', 'deletion', 'kpi', 'entknüpfung'].includes(
            lowerCaseGlobalSearch,
          )
        ) {
          navigateTo = '/servicePortal';
        } else if (
          ['installation', 'ínstallations'].includes(lowerCaseGlobalSearch)
        ) {
          navigateTo = '/servicePortal/installation';
        } else if (
          [
            'search',
            'searches',
            'project',
            'projects',
            'projekt',
            'projekte',
          ].includes(lowerCaseGlobalSearch)
        ) {
          navigateTo = '/servicePortal/search';
        } else if (
          ['hyper', 'hyperTable', 'hyperTables', 'timescale'].includes(
            lowerCaseGlobalSearch,
          )
        ) {
          navigateTo = '/servicePortal/admin/hyperTable';
        }

        if (navigateTo) {
          navigate(navigateTo);
          submitGlobalSearch('');
        } else {
          submitGlobalSearch(globalSearch);
        }
      }
    },
    [globalSearch, submitGlobalSearch, navigate],
  );

  const wasFetching = usePrevious(isFetching);
  useEffect(() => {
    if (wasFetching && !isFetching && isSuccess) {
      // api call completed navigate to found specific installation/project or the search page
      if (!isNil(data?.specific?.installation?.id)) {
        navigate(
          `/servicePortal/installation/${data?.specific?.installation?.id}`,
        );
      } else if (!isNil(data?.specific?.projectId)) {
        navigate(`/servicePortal/project/${data?.specific?.projectId}`);
      } else {
        const [newSearchParams] = submitSearch(submittedGlobalSearch);
        navigate(`/servicePortal/search?${newSearchParams.toString()}`);
      }
      // reset the global search bar
      submitGlobalSearch('');
    }
  }, [
    isFetching,
    isSuccess,
    data,
    navigate,
    submitSearch,
    submitGlobalSearch,
    submittedGlobalSearch,
    wasFetching,
  ]);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isAccountMenuOpen}
      onClose={onAccountMenuClose}
    >
      <MenuItem onClick={handleOnAccountButton}>
        {t('navigation.account')}
      </MenuItem>
      <MenuItem onClick={handleOnLogout}>{t('navigation.logout')}</MenuItem>
    </Menu>
  );

  const appBarSx = useMemo(
    () => ({
      zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
      marginTop: (theme: Theme) =>
        `calc(${theme.mixins.toolbar.minHeight}px * ${prevToolbarCount})`,
    }),
    [prevToolbarCount],
  );

  return (
    <>
      <AppBar position="fixed" component="nav" sx={appBarSx}>
        <Toolbar>
          <IconButton
            onClick={onToggleDrawer}
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            color={'white'}
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            Service Portal{title ? ` - ${title}` : ''}
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={`${t('common.search') ?? 'search'}…`}
              inputProps={{ 'aria-label': t('common.search') ?? 'search' }}
              value={globalSearch}
              onChange={onSearchInputChange}
              onKeyDown={onSearchInputKeyDown}
              disabled={isFetching}
            />
          </Search>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'flex' } }}>
            <Link to="/servicePortal">
              <IconButton size="large" style={{ color: 'white' }}>
                <Badge badgeContent={notificationCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Link>
            <IconButton
              size="large"
              edge="end"
              onClick={onAccountMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </>
  );
};

export default PrimarySearchAppBar;
