import ApartmentIcon from '@mui/icons-material/Apartment';
import ListIcon from '@mui/icons-material/List';
import EuroIcon from '@mui/icons-material/Euro';
import BiotechIcon from '@mui/icons-material/Biotech';
import {
  Container,
  CssBaseline,
  CSSObject,
  styled,
  Theme,
  Toolbar,
} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import React, { useMemo } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import PrimarySearchAppBar from './PrimarySearchAppBar';
import { Link, useLocation } from 'react-router-dom';
import { hasPermission, IoTCloudPermissionEnum } from '../../lib/helpers';
import { useGetMeQuery } from '../../redux/api/iotCloud';
import DebugAppBar from './DebugAppBar';
import config from '../../config';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const getDrawerItems = (t: TFunction) => [
  {
    name: 'Dashboard',
    path: '/servicePortal',
    Icon: ListIcon,
  },
  {
    name: t('servicePortal.search.title'),
    path: '/servicePortal/search',
    Icon: SearchIcon,
  },
  {
    name: t('servicePortal.installationPage.title'),
    path: '/servicePortal/installation',
    Icon: ApartmentIcon,
  },
  {
    name: t('servicePortal.electricityPricePage.title'),
    path: '/servicePortal/price/electricity',
    Icon: EuroIcon,
  },
  {
    name: t('servicePortal.electricityPriceAlgorithmPlaygroundPage.title'),
    path: '/servicePortal/price/electricity/algorithmPlayground',
    Icon: BiotechIcon,
    permissions: [IoTCloudPermissionEnum.PO],
  },
  {
    name: 'HyperTables',
    path: '/servicePortal/admin/hyperTable',
    Icon: ListIcon,
    permissions: [IoTCloudPermissionEnum.Admin],
  },
];

const DrawerListItem = ({
  name,
  path,
  permissions,
  onClick,
  Icon,
}: {
  name: string;
  path: string;
  permissions?: IoTCloudPermissionEnum[];
  onClick?: () => void;
  Icon: React.ElementType;
}) => {
  const { data: getMeData } = useGetMeQuery();
  const location = useLocation();
  const isSelected = location.pathname === path;

  if (permissions && permissions.some(p => !hasPermission(getMeData, p))) {
    return null;
  }

  return (
    <ListItem key={name} disablePadding>
      <ListItemButton
        component={Link}
        to={path}
        onClick={onClick}
        selected={isSelected}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  );
};

const DrawerList = ({
  onClick,
  toolbarCount = 1,
}: {
  onClick: () => void;
  toolbarCount?: number;
}) => {
  const { t } = useTranslation('translation');
  const drawerItems = useMemo(() => getDrawerItems(t), [t]);

  const sx = useMemo(
    () => ({
      width: 250,
      pt: (theme: Theme) =>
        `calc((${
          theme.mixins.toolbar.minHeight
        }px * ${toolbarCount}) + ${theme.spacing(1)})`,
    }),
    [toolbarCount],
  );
  return (
    <Box sx={sx} role="presentation">
      <List>
        {drawerItems.map(itemArgs => (
          <DrawerListItem key={itemArgs.name} onClick={onClick} {...itemArgs} />
        ))}
      </List>
    </Box>
  );
};

const MiniDrawer = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const ServicePortalLayout = ({ children }: { children: React.ReactNode }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const onToggleDrawerFactory = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  const showDebugToolbar = config.isDevEnv;
  const contentBoxStyles = useMemo(() => {
    const base = {
      p: 3,
      // handle drawer open/close
      ml: (theme: Theme) =>
        drawerOpen ? `${drawerWidth}px` : `calc(${theme.spacing(7)} + 1px)`,
      transition: (theme: Theme) =>
        theme.transitions.create('transform', {
          easing: theme.transitions.easing.sharp,
          duration: drawerOpen
            ? theme.transitions.duration.leavingScreen
            : theme.transitions.duration.enteringScreen,
        }),
    };
    if (showDebugToolbar) {
      return {
        ...base,
        mt: (theme: Theme) => `${theme.mixins.toolbar.minHeight}px`,
      };
    }
    return base;
  }, [drawerOpen, showDebugToolbar]);

  return (
    <Container disableGutters={true} maxWidth={false}>
      <CssBaseline />
      {showDebugToolbar && <DebugAppBar />}
      <PrimarySearchAppBar
        prevToolbarCount={showDebugToolbar ? 1 : 0}
        onToggleDrawer={onToggleDrawerFactory(!drawerOpen)}
      />
      <MiniDrawer
        variant="permanent"
        open={drawerOpen}
        onClose={onToggleDrawerFactory(false)}
      >
        <DrawerList
          onClick={onToggleDrawerFactory(false)}
          toolbarCount={showDebugToolbar ? 2 : 1}
        />
      </MiniDrawer>
      <Box component="main" sx={contentBoxStyles}>
        <Toolbar />
        {children}
      </Box>
    </Container>
  );
};

export default ServicePortalLayout;
