import { Edit } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangeAuth0UserIdDialogConfirmationStage from './ChangeAuth0UserIdConfirmationStage';
import ChangeAuth0UserIdDialogResultStage from './ChangeAuth0UserIdDialogResultStage';
import { BACKEND_ROUTE } from '../../../../../config';
import ApiContext from '../../../../../context/ApiContext';
import CustomDialogTitle from '../../../../common/dialog/CustomDialogTitle';

type ChangeAuth0UserIdDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  installationId: number | string;
  customerPortalAuth0UserId?: string;
  auth0UserId: string;
};

const ChangeAuth0UserIdDialog = ({
  isOpen,
  onClose,
  installationId,
  customerPortalAuth0UserId,
  auth0UserId,
}: ChangeAuth0UserIdDialogProps) => {
  const { api, routes } = useContext(ApiContext);
  const { t } = useTranslation('translation');

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [resultText, setResultText] = useState<string | null>(null);

  const onConfirm = async (newAuth0UserId: string) => {
    setIsConfirmed(true);
    try {
      const response = await api.POST({
        endpoint: routes[BACKEND_ROUTE.INSTALLATION],
        routeParams: {
          installationId,
        },
        data: {
          auth0UserId: newAuth0UserId,
        },
      });
      if (response.ok) {
        setResultText(
          t('servicePage.changeAuth0UserIdDialog.auth0UserIdChanged'),
        );
        return;
      }
      if (response.status === 403) {
        setResultText(t('common.forbiddenErrorMessage'));
        return;
      }
      setResultText(t('servicePage.changeAuth0UserIdDialog.defaultError'));
    } catch (e) {
      setResultText(t('servicePage.changeAuth0UserIdDialog.defaultError'));
    }
  };

  const resetDialog = () => {
    setIsConfirmed(false);
    setResultText(null);
  };

  useEffect(() => {
    if (isOpen === false) {
      const timeout = setTimeout(resetDialog, 300);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      sx={{ maxWidth: '670px', mx: 'auto' }}
    >
      <CustomDialogTitle icon={<Edit fill="black" />} onClose={onClose}>
        {t('servicePage.changeAuth0UserIdDialog.title')}
      </CustomDialogTitle>
      {isConfirmed ? (
        <ChangeAuth0UserIdDialogResultStage
          onClose={onClose}
          resultText={resultText}
        />
      ) : (
        <ChangeAuth0UserIdDialogConfirmationStage
          onClose={onClose}
          onConfirm={onConfirm}
          auth0UserId={auth0UserId}
          customerPortalAuth0UserId={customerPortalAuth0UserId}
        />
      )}
    </Dialog>
  );
};

export default ChangeAuth0UserIdDialog;
