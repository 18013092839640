import { Stack } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';

import { useGetInstallationStatusQuery } from '../../../redux/api/montageApi';
import { GetProjectServiceDataResult } from '../../../redux/api/montageApi/types';
import DisconnectSmartboxDialogTriggerButton from './dialogs/DisconnectSmartboxDialog/DisconnectSmartboxDialogTriggerButton';
import EkdIdReminderEmailDialogTriggerButton from './dialogs/EkdIdReminderEmailDialog/EkdIdReminderEmailDialogTriggerButton';
import GatewayConnectionDialogTriggerButton from './dialogs/GatewayConnectionDialog/GatewayConnectionDialogTriggerButton';
import { useGetInstallationDeletionStatusQuery } from '../../../redux/api/iotCloud';
import { SimpleJobState } from '../../../redux/api/iotCloud/typeCopy';
import { InstallationConnectionStatus } from '../../../redux/api/iotCloud/types';
import ChangeAuth0UserIdDialogTriggerButton from './dialogs/ChangeAuth0UserIdDialog/ChangeAuth0UserIdDialogTriggerButton';

type InstallationActionsProps = {
  projectId?: string | number | null;
  installationId?: string | number;
  serviceData?: GetProjectServiceDataResult;
};

const InstallationActions = ({
  projectId,
  installationId,
  serviceData,
}: InstallationActionsProps) => {
  const {
    data: installationStatusData,
    isLoading: installationStatusIsLoading,
    isSuccess: installationStatusIsSuccess,
  } = useGetInstallationStatusQuery(installationId ?? skipToken);
  const isConnected =
    installationStatusData?.connectionStatus ===
    InstallationConnectionStatus.Connected;
  const isProjectConnected =
    installationStatusData?.connectionStatus ===
    InstallationConnectionStatus.ProjectConnected;

  const { data: initialInstallationDeletionStatus } =
    useGetInstallationDeletionStatusQuery(installationId ?? skipToken);
  const {
    data: installationDeletionStatus,
    isSuccess: installationDeletionStatusIsSuccess,
    isError: installationDeletionStatusIsError,
  } = useGetInstallationDeletionStatusQuery(installationId ?? skipToken, {
    pollingInterval: initialInstallationDeletionStatus?.state ? 20000 : 0, // 20s,
    skipPollingIfUnfocused: true,
  });
  const isCurrentlyDisconnecting =
    installationDeletionStatus?.state === SimpleJobState.processing ||
    installationDeletionStatus?.state === SimpleJobState.queued;

  const canConnect =
    projectId && serviceData && !installationStatusIsLoading && !isConnected;
  const canDisconnect =
    installationId &&
    installationStatusIsSuccess &&
    (isConnected || isProjectConnected) &&
    installationDeletionStatusIsSuccess &&
    !installationDeletionStatusIsError &&
    !isCurrentlyDisconnecting;
  const canRemindEkdId =
    projectId && serviceData && !serviceData?.customer?.ekdIdStatus?.userId;

  return (
    <Stack flex={1} gap={2}>
      <GatewayConnectionDialogTriggerButton
        disabled={!canConnect}
        projectId={projectId ?? ''}
        customerNumber={serviceData?.customer?.number ?? null}
        serialNumber={
          serviceData?.gateway?.protocolSerialNumber ??
          serviceData?.gateway?.lmSerialNumber ??
          null
        }
        gatewaySecret={null}
      />
      <EkdIdReminderEmailDialogTriggerButton
        disabled={!canRemindEkdId}
        emailAddress={serviceData?.customer?.email ?? ''}
        projectId={projectId ?? ''}
      />
      <DisconnectSmartboxDialogTriggerButton
        disabled={!canDisconnect}
        installationId={installationId}
        isLoading={isCurrentlyDisconnecting}
      />
      <ChangeAuth0UserIdDialogTriggerButton
        installationId={installationId}
        auth0UserId={serviceData?.installation?.userAccount?.auth0UserId}
        customerPortalAuth0UserId={serviceData?.customer.ekdIdStatus?.userId}
        isLoading={installationStatusIsLoading}
        disabled={
          !canDisconnect || !serviceData?.installation?.userAccount?.auth0UserId
        }
      />
    </Stack>
  );
};

export default InstallationActions;
