import { BaseQueryApi, fetchBaseQuery } from '@reduxjs/toolkit/query';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';

import { RootState } from '..';
import config from '../../config';
import { setAccessToken, setShouldShowLmLogin } from '../auth';
import isNil from 'lodash/isNil';
import { LmSessionLoginResult, UserProfile } from './montageApi/types';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { selectAccessToken } from '../selectors';

const montageApiBaseQuery = fetchBaseQuery({
  baseUrl: config.api.baseUrl,
  prepareHeaders: headers => {
    [
      ...Object.entries(config.api.defaultHeaders),
      ...Object.entries(config.api.typedHeaders),
    ]
      .filter(([key, value]) => isNil(headers.get(key)))
      .forEach(([key, value]) => {
        headers.set(key, value);
      });
  },
  credentials: 'include',
});

const iotCloudBaseQuery = fetchBaseQuery({
  baseUrl: config.iotCloudApi.baseUrl,
  prepareHeaders: headers => {
    headers.set(
      'Content-Type',
      headers.get('Content-Type') || 'application/json',
    );
    headers.set('Accept', headers.get('Accept') || 'application/json');
    headers.set('x-client-type', 'assemblyFrontend');
    headers.set('x-auth-type', 'montageJwt');
  },
  credentials: 'include',
});

const getArgsWithToken = (args: FetchArgs | string, token: string) => {
  if (typeof args === 'string') {
    return { url: args, headers: { Authorization: `Bearer ${token}` } };
  }
  return {
    ...args,
    headers: {
      ...(args.headers ?? {}),
      Authorization: `Bearer ${token}`,
    },
  };
};

export const lmSessionQueryFn = async (
  api: BaseQueryApi,
  extraOptions: any = {},
) => {
  const lmSessionResult = (await montageApiBaseQuery(
    {
      url: 'api/v1/auth/login/lmSession',
      method: 'POST',
    },
    api,
    extraOptions,
  )) as QueryReturnValue<LmSessionLoginResult, FetchBaseQueryError>;
  const accessToken = lmSessionResult.data?.access_token;
  if (accessToken) {
    // update the stored access token
    api.dispatch(setAccessToken(accessToken));
    // disable the login overlay
    api.dispatch(setShouldShowLmLogin(false));
    console.log('Got new access token, disabling login overlay', {
      accessToken,
      lmSessionResult,
    });
  } else if (lmSessionResult.error && lmSessionResult.error?.status === 401) {
    // remove the old stored access token
    api.dispatch(setAccessToken(null));
    // enable the login overlay
    api.dispatch(setShouldShowLmLogin(true));
    console.log('Got new lmSession 401, enabling login overlay', {
      accessToken,
      lmSessionResult,
    });
  }
  return [accessToken, lmSessionResult] as const;
};

// Used to determine if user is logged in.
export const profileQueryFn = async (
  _args: any,
  api: BaseQueryApi,
  extraOptions: any,
  _baseQuery: any,
) => {
  const result = await montageApiBaseQuery(
    'api/v1/auth/user/profile',
    api,
    extraOptions,
  );
  if (result.error && result.error.status === 401) {
    api.dispatch(setShouldShowLmLogin(true));
  } else {
    api.dispatch(setShouldShowLmLogin(false));
  }
  return result as QueryReturnValue<
    UserProfile,
    FetchBaseQueryError,
    FetchBaseQueryMeta
  >;
};

export const baseQueryWithReauthFactory =
  (
    baseQuery: BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
  ): BaseQueryFn =>
  async (args, api, extraOptions) => {
    const state = api.getState() as RootState;
    const accessToken = selectAccessToken(state);

    // Optimistic case first
    const result = await baseQuery(
      accessToken ? getArgsWithToken(args, accessToken) : args,
      api,
      extraOptions,
    );
    if (!(result.error && result.error.status === 401)) {
      return result;
    }

    // Initial query failed with a 401
    // (try to) get a new accessToken
    const [newAccessToken] = await lmSessionQueryFn(api, extraOptions);
    if (newAccessToken) {
      // Retry the original query with the new accessToken
      return await baseQuery(
        getArgsWithToken(args, newAccessToken),
        api,
        extraOptions,
      );
    }

    // return the original result if no new accessToken was obtained
    return result;
  };

export const montageApiBaseQueryWithReauth =
  baseQueryWithReauthFactory(montageApiBaseQuery);
export const iotCloudBaseQueryWithReauth =
  baseQueryWithReauthFactory(iotCloudBaseQuery);
