import { DeleteForever } from '@mui/icons-material';
import { DialogContent, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/ex.svg';
import DialogFooter from '../../../../common/dialog/DialogFooter';

type ChangeAuth0UserIdDialogConfirmationStageProps = {
  onClose: () => void;
  onConfirm: (newAuth0UserId: string) => void;
  auth0UserId: string;
  customerPortalAuth0UserId?: string;
};

const ChangeAuth0UserIdDialogConfirmationStage = ({
  onClose,
  onConfirm,
  auth0UserId,
  customerPortalAuth0UserId,
}: ChangeAuth0UserIdDialogConfirmationStageProps) => {
  const { t } = useTranslation('translation');

  const [newAuth0UserId, setNewAuth0UserId] = useState(
    customerPortalAuth0UserId,
  );

  return (
    <DialogContent>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t('servicePage.changeAuth0UserIdDialog.warningText')}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontWeight: 700 }}>
          {t('servicePage.changeAuth0UserIdDialog.currentAuth0UserIdLabel')}
        </Typography>
        <Typography>{auth0UserId}</Typography>
      </div>
      <TextField
        label={t('servicePage.changeAuth0UserIdDialog.newAuth0UserIdLabel')}
        onChange={e => setNewAuth0UserId(e.target.value)}
        value={newAuth0UserId}
        sx={{ mt: 2 }}
        size="small"
        fullWidth
      />
      <DialogFooter
        buttonsData={[
          {
            colorVariant: 'secondary',
            icon: <CloseIcon />,
            label: t('common.cancel'),
            onClick: onClose,
          },
          {
            colorVariant: 'primary',
            icon: <DeleteForever />,
            label: t('common.confirm'),
            onClick: () => newAuth0UserId && onConfirm(newAuth0UserId),
          },
        ]}
      />
    </DialogContent>
  );
};

export default ChangeAuth0UserIdDialogConfirmationStage;
