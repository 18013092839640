import { merge } from 'lodash';
import { BACKEND_ROUTE } from './constants';

export * from './constants';

const isDevEnv = process.env.NODE_ENV === 'development';

const getEnvName = () => {
  const host = window.location?.host;
  if (typeof host !== 'string') {
    return 'prod';
  }
  if (host.includes('test1')) {
    return 'test1';
  }
  if (host.includes('test2')) {
    return 'test2';
  }
  if (host.includes('test3')) {
    return 'test3';
  }
  if (host.includes('staging1')) {
    return 'staging1';
  }
  if (host.includes('staging2')) {
    return 'staging2';
  }
  if (host.includes('localhost')) {
    return 'dev';
  }
  if (host.includes('local')) {
    return 'lmLocal';
  }
  return 'prod';
};

const envName = getEnvName();

let localMontageDev = null;
let localIotDev = null;
localMontageDev = 'http://localhost:3002';
localIotDev = 'http://localhost:3000';

const baseUrlByEnv = {
  test1: 'https://montage-api.test1.leads-middleware.de',
  test2: 'https://montage-api.test2.leads-middleware.de',
  test3: 'https://montage-api.test3.leads-middleware.de',
  staging1: 'https://montage-api.staging1.leads-middleware.de',
  staging2: 'https://montage-api.staging2.leads-middleware.de',
  prod: 'https://montage-api.leads-middleware.de',
  lmLocal: 'https://montage-api.lm.local',
  dev: localMontageDev || 'https://montage-api.test3.leads-middleware.de',
};

//TODO: adjust
const iotCloudBaseUrlByEnv = {
  test1: 'https://product.test3.ekd-iot.de',
  test2: 'https://product.test3.ekd-iot.de',
  test3: 'https://product.test3.ekd-iot.de',
  staging1: 'https://product.staging.ekd-iot.de',
  staging2: 'https://product.iot.staging2.leads-middleware.de',
  prod: 'https://product.ekd-iot.de',
  lmLocal: 'https://product.iot.lm.local',
  dev: localIotDev ?? 'https://product.test3.ekd-iot.de',
};

// These are public keys and can be commited
const testClientIdToken =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhc3NlbWJseUZyb250ZW5kIiwiaXNzIjoibW9udGFnZUJhY2tlbmQiLCJ0eXBlIjoicHVibGljIiwiZGV2aWNlIjpudWxsLCJpYXQiOjE2ODIzNDgwMzJ9.ntOEnDVdwqQ9afxTVpdNRJXvyes_vCW5hA8kjEw8RxenwBpedKuRFblL_KIwrXQCsV5VZSN05Vt__5PRfORMrSJxn23ud1eqLOdIeKXeZqGscoQ3-MikBeN0vhcitCe-Me3ySjZfv-grSGxwczOWF6Mt-S014p8WFhdla5dlBfcHxeBzajMfg7wYJkXj5dPRM8OJlNyQJl1SlhsBorQGCfcFBZlbH6K0QsovM5Q390DvERgX3GUMUo6el4HlRITuz73rCMUKgcABmkNHXCu0N0-VKJg5O59dwZoHqWPFFQ8WsBmz7JvLuvYKp5Jtubu_gfvAYt88XIt2NLiMv4oYihgR08Mt7N6COvMKbxIGc6i_R91qt1Y3FkUcmtgKEf1MtyP15iB4AW87huzHMTySZXMbPa9ENpaxtAE21d70diAIpJBLkKOiTWbnYT-9cD64gcLpDfMvxKNqx5-9GSj3ZE4Bib8sXQgWkYJBYq2MUqgNxROMnST_xN9HHSepqvPAKaD4BXp_QsHIsDZ19jbXqSKnq5OPmS4zdhQ1wIrz6yC6iSfkNif8lhUhKoBQjarKI0zWgh4xIodjMp4_Xe-t_dguBMYnp3XnqIB-MlNCc2bY4pWi3iLj140dyYcWZqAuWvWNRNZU6EdS7bM4DUDHN9bavV1gm9c88866-ZkNrn0';
const staging1ClientIdToken =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhc3NlbWJseUZyb250ZW5kIiwiaXNzIjoibW9udGFnZUJhY2tlbmQiLCJ0eXBlIjoicHVibGljIiwiZGV2aWNlIjpudWxsLCJpYXQiOjE2ODI1OTE0NzF9.CkxgYQRHok1aSkYXFiZm-ojXjVQtv0ubmHWUP9eaE6hnD7f79DgICOghy1iLopE-gBDUNzPFok8VdsC8rttvsteFBqX7o6tZpN0nHC1df5_Nun1LLhmWj-W0hk92hF3pEqavdF5B1cl520nlkkhU45IFwg1-aihFof93dBV5dmqdnLKrpuI_mRG4iFg2STaTHQNccfkpvqDXn5KWa_f5Z6sGgTBhXD-aweSng5astMbfKSFFvogZftb1FSHh_EcR8AFkfhEgVmqbnjOfSY3mm6l3X49D6ctig0YDwdDP0FBLZR6RyaWFKwiyR360SUUFOm7pEiBt5JP_PA40BdMYcPL1wJXH4DW8HI2B_e7KQqvCFweW0wPVR7_g1Xku_PTnlzkYCKr0f0v9HzBclTmg90kbKAGJxT9d1_7JjW5jIhMY62ilOYHJrkbQLB_xxzQM3YFDvTkm7I6P_W3XQZlRxwz8bJGl8JnJ2tgoRZnUavJIL_eGkM4gybnbj4H9DfMLC1ixiw_z0WH77fhyMhC8qpaf9p5q8zyWew5qXGpamkjqydaCdHguVsRPlniEZKPz6shF81H7Lvnj2b0xtE2uttAzsdCsJOsRJfn_Yy7XXOapXKwc_gnyPMJDW9zyBIHGxms0dNCx5UMPvaRezoB6MT6ndFgchK3V9vEW6L-Xgvg';
const staging2ClientIdToken =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhc3NlbWJseUZyb250ZW5kIiwiaXNzIjoibW9udGFnZUJhY2tlbmQiLCJ0eXBlIjoicHVibGljIiwiZGV2aWNlIjpudWxsLCJpYXQiOjE3Mzc2MDMxMjZ9.oo6IM8MBCB29jgYqUTxH6SS0PoB0HEnPScKBAXi5_HR8O_ij3nqXLZevRIj5Y_X1VHg4sO32e-tQCPAmlWTLFbIYbyXoyEhFlbl66Bv7CYQpQTXRV5gzZbaSEOdD0VAw4HvkCtJit2S6Z1rBIvft5g39axCmEaDpKKGCtTFzj4stYmlBvVn7HHONNUrjRz3q8k-M33kShu2cYtRFpC1I6dL5073V8ovXpuzH31_yQuWuvyX98J620KdmqTQGyN0OT8HJQN8iJR5eDDNy0rgPQu2YwAEMbRh2R3gmHYSvHZQic9LANeKekBFg0s7SwyIE5myMYfDgfWXq88wP_jVV8WR3ZJ_vGqHxsAl_ITPGKyt2TVp_3yAqClMm55gaXO_7RnOJlspzIae9YFgmlb-o3d6E8kYMLJxWVVS4mjVC3Fg5AgbUH_zR0Uap-VnMq8b_NdUSGaq9iwby5U3i_jtZp8cdweI3Z0btdx0FF9y2Cq4Sb7Us1eWq-Oe40yixIrkI6OEYdJ5cZAJsvzxzh2WjfBNd1OQLXoNnlnEmDxamuXW5uJiCTdvtpeoYlVb9Y5TX5MRClwktd1deMcicziZcD9thHPiU7dGcXWWnfyzuBeurXCvKH8i6bbyaw5Sx1jTnZeunIU_A9aKOQ_8z977tPjJT05ZgGXixTLNU-GizdPo';
const prodClientIdToken =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhc3NlbWJseUZyb250ZW5kIiwiaXNzIjoibW9udGFnZUJhY2tlbmQiLCJ0eXBlIjoicHVibGljIiwiZGV2aWNlIjpudWxsLCJpYXQiOjE2ODI1OTE1MDZ9.xBHUe0AlDrexYORd9IUGEZSzRn9x8BY-ZbO0JioI0AVgnLAxDuwchOatnsEa6enLsRzRwPgKjhtshWRSiC1UG0KTl5TrEoh9AwbhWW95T7PdvhoSbpUpNjn9FEjGZulx19Ju7aEfD0bgfHAj5G9iIKyYHEbSXWbMH5EBldSyop6XH27Ei_6vqe8CYGVeVsSSpUh97LGMFNU-1PUU5MgWlragdsD0s-qAhJ7iNVFow6rzVTr_V0My-_yTd_cOeLhmLaj1Vzq2oJiIWnEfc1DbY-CAhUMrM37A8ZPsi8qyAzHUgx5JOeo8oOOk9V-llxwvI73XMa09ERXwjBxxQ6lslLw1n5Xc5W5HkeFjK-dsmqIH7MrP8rCiAbFQLT8ZWtxiBLqS8ZDM-ELwG2vSCa9VMKJTbfLnieMl8qBkbJKwiAzOLiD_9zkui5y_zrzYg-BD4FAWmDSFCYcXNX02wOFrTaZwcIA1tDdfIbG-DcwWhS9lRfXeBWD91lblEJtJLCeJQXqPzzg5c8Z7j0ISlgsKJr6Gom31k3iOH92QoNFeReDqySBVYLtCQPklpqR_WyrmOyWXIwS-Z8XGNKgRN9uFHoBq8Wn2IaEngFWiR8A5F7Xn0mx2OeCMbv68ICrcyox6MXVb0g5CHNzS_hJo8bkmJnHELqmqO7MFIFxABY6kJRg';
const test2ClientIdToken =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhc3NlbWJseUZyb250ZW5kIiwiaXNzIjoibW9udGFnZUJhY2tlbmQiLCJ0eXBlIjoicHVibGljIiwiZGV2aWNlIjpudWxsLCJpYXQiOjE3MDk3MzI5NDN9.SkT5b8U0f75IOdkQbVH0jo0p0dGJQpMvCGtJ2_dVcbfrXo1YsA3fVwAsKrscyWJJRw73Ml_2LSzDxGowCjC4GVrum9DRj7RgIsIlKZbitYI5y1FvDusCQvp6_gx69WYFhFcr1Tk0pCeGfituievhhNbDlMCT6IWHh014ZZTFa865HBpfiKZ3QhVB1LS_iC0YiKXptBLL4Eg3jDlcSC7a7CV-5luWB7_N2l3dJX1qo2aqQmcDzbI-0wRcDRv4ITJ5_qZv2oEtDNvcMzOLADMy0nEslaeFYyyVG_6hgEA2pHYiuRT8PAIUHa40w2iu7wohBOZUlCKT6LfiT3q_ANjuIXL8u86FWtxTGrsgZ0pEfJzfmrEt8hfqPJEI8DGXsi_qiAmpCtLdlL1R-rcJWXg3KVWeUwJcPzaF0RpEbgF_AxijnjNGoOhNBBlHzHPndXel5hwBE3c78qoBYzmYSp6pBsFOREu8ieU1sPnEQ-qf0zash-8rkFYeu1qG1By9f_7HLgVis51sKAv_iFZl1-9frcgO6zonxvUcrTWS9xeYVK_d8VsgxjLwXPeHqQpdcGtgYTWzw8v-lnZzdqPlDLJJWHK5QGsCOYQSJ4GeUCMEFzOdH3BzUOnrbCrf3gLmgPi_7c4WCbNGPkoczoezBJGCv2CYXcc3WPUJF2I5vIByvVw';

const clientIdTokenByEnv = {
  test1: testClientIdToken,
  test2: test2ClientIdToken,
  test3: testClientIdToken,
  lmLocal: testClientIdToken,
  staging1: staging1ClientIdToken,
  staging2: staging2ClientIdToken,
  prod: prodClientIdToken,
  dev: testClientIdToken,
};

export const clientIdToken =
  process.env.REACT_APP_CLIENT_ID_TOKEN ?? clientIdTokenByEnv[envName];

if (isDevEnv && process.env.REACT_APP_DEV_TOKEN) {
  document.cookie = 'montageAuthToken=' + process.env.REACT_APP_DEV_TOKEN;
}

const getLeadsMiddlewareBaseUrl = () => {
  if (envName === 'prod') {
    return 'https://www.leads-middleware.de';
  }
  if (envName === 'lmLocal') {
    return 'https://lm.local';
  }
  if (envName === 'dev') {
    return 'http://test3.leads-middleware.de';
  }
  return `https://${envName}.leads-middleware.de`;
};
export const getLeadsMiddlewareLoginUrl = (
  redirectAfterLogin = window.location.href,
) =>
  `${getLeadsMiddlewareBaseUrl()}/login/login.php?redirectAfterLogin=${encodeURIComponent(
    redirectAfterLogin,
  )}`;

const devConfig = {
  reactStrictMode: false,
  api: {
    logger: console,
  },
};

const config = {
  isDevEnv,
  envName,
  api: {
    baseUrl: `${baseUrlByEnv[envName]}`,
    defaultTimeout: 30000,
    defaultHeaders: {
      'x-client-id-token': clientIdToken,
      ...(isDevEnv &&
        process.env.REACT_APP_DEV_TOKEN && {
          Authorization: 'Bearer ' + process.env.REACT_APP_DEV_TOKEN,
        }),
    },
    typedHeaders: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'include',
    routes: {
      [BACKEND_ROUTE.VISUAL_DOCUMENTATION]:
        'api/v1/frontend/project/{projectId}/visual_documentation',
      [BACKEND_ROUTE.VISUAL_DOCUMENTATION_UPLOAD]:
        'api/v1/frontend/project/{projectId}/visual_documentation',
      [BACKEND_ROUTE.VISUAL_DOCUMENTATION_CATEGORIES]:
        'api/v1/frontend/project/visual_documentation_categories',
      [BACKEND_ROUTE.PROTOCOL_TRANSLATION]: 'api/v1/translation/protocol',
      [BACKEND_ROUTE.PROJECT_SERVICE_DATA]:
        'api/v1/frontend/project/{projectId}/service',
      [BACKEND_ROUTE.INSTALLATION_STATUS]:
        'api/v1/frontend/iot_cloud/installation/{installationId}/status',
      [BACKEND_ROUTE.GATEWAY_CONNECTION]:
        'api/v1/project/{projectId}/connect_gateway/{serialNumber}',
      [BACKEND_ROUTE.EKD_ID_REMINDER_EMAIL]:
        'api/v1/frontend/project/{projectId}/customer_mail',
      [BACKEND_ROUTE.ATTACHMENT]: 'api/v1/project/attachment/{fileId}',
      [BACKEND_ROUTE.INSTALLATION_BATTERY_CHARGE_HISTORY]:
        'api/v1/frontend/iot_cloud/installation/{installationId}/battery_charge/history',
      [BACKEND_ROUTE.DELETE_INSTALLATION]:
        'api/v1/frontend/iot_cloud/installation/{installationId}',
      [BACKEND_ROUTE.DELETE_VISUAL_DOCUMENTATION]:
        'api/v1/visual_documentation/{uuid}',
      [BACKEND_ROUTE.USER_PROFILE]: 'api/v1/auth/user/profile',
      [BACKEND_ROUTE.INSTALLATION]:
        'api/v1/frontend/iot_cloud/installation/{installationId}/auth0UserId',
    },
    logger: {
      debug: () => undefined,
      info: console.info,
      error: console.error,
      warn: console.warn,
    },
  },
  iotCloudApi: {
    baseUrl: `${iotCloudBaseUrlByEnv[envName]}`,
  },
  servicePage: {
    googleMapsLinkByText: text =>
      text ? `https://maps.google.com/?q=${text}` : null,
    googleMapsLinkByCoordinates: (lat, lng) =>
      lat && lng ? `https://maps.google.com/?q=${lat},${lng}` : null,
    auth0UserProfileLink: auth0UserId =>
      auth0UserId
        ? `https://manage.auth0.com/dashboard/eu/ekd-solar-prod/users/${btoa(
            auth0UserId,
          )}`
        : null,
  },
};

export default isDevEnv ? merge({}, config, devConfig) : config;
