import { Person } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangeAuth0UserIdDialog from './ChangeAuth0UserIdDialog';
import ButtonWithIcon from '../../../../common/ButtonWithIcon';

type ChangeAuth0UserIdDialogTriggerButtonProps = {
  installationId: string | number | undefined;
  auth0UserId: string | null | undefined;
  customerPortalAuth0UserId: string | undefined;
  isLoading: boolean;
  disabled?: boolean;
};

const ChangeAuth0UserIdDialogTriggerButton = ({
  installationId,
  auth0UserId,
  customerPortalAuth0UserId,
  isLoading,
  disabled,
}: ChangeAuth0UserIdDialogTriggerButtonProps) => {
  const { t } = useTranslation('translation');
  const [isOpen, setIsOpen] = useState(false);

  const toggleDialogVisibility = () => {
    console.log({ installationId, auth0UserId });
    setIsOpen(isOpen => !isOpen);
  };

  return (
    <>
      <Box>
        <ButtonWithIcon
          disabled={disabled}
          isLoading={isLoading}
          variant="contained"
          colorVariant="secondary"
          onClick={toggleDialogVisibility}
          icon={<Person fill="white" />}
        >
          {t('servicePage.changeAuth0UserIdDialog.triggerButtonLabel')}
        </ButtonWithIcon>
      </Box>
      {installationId && auth0UserId && (
        <ChangeAuth0UserIdDialog
          installationId={installationId}
          auth0UserId={auth0UserId}
          customerPortalAuth0UserId={customerPortalAuth0UserId}
          isOpen={isOpen}
          onClose={toggleDialogVisibility}
        />
      )}
    </>
  );
};

export default ChangeAuth0UserIdDialogTriggerButton;
