import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { isNil } from 'lodash';
import {
  selectSharedInputValue,
  selectSubmittedInput,
  selectSubmittedInputValue,
} from '../redux/selectors';
import {
  setSharedInputValue,
  setSubmittedInputValue,
  SubmittedParamExposeType,
} from '../redux/servicePortal';
import { RootState } from '../redux';
import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T | undefined>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const usePreviousPersistent = <T>(value: T) => {
  // initialise the ref with previous and current values
  const ref = useRef<{ value: T; prev: T | null }>({
    value: value,
    prev: null,
  });

  const current = ref.current.value;

  // if the value passed into hook doesn't match what we store as "current"
  // move the "current" to the "previous"
  // and store the passed value as "current"
  if (value !== current) {
    ref.current = {
      value: value,
      prev: current,
    };
  }

  // return the previous value only
  return ref.current.prev;
};

export enum GlobalParamEnum {
  Search = 'q',
  LoadMore = 'qc',
  GlobalSearch = 'gq',
  DeviceType = 'deviceType',
  EP_Range = 'ep_r', // Price Signal Algorithm Playground From, To
  PSAP_Algorithm = 'psap_a', // Price Signal Algorithm Playground Algorithm
  PSAP_AlgorithmConfig = 'psap_ac', // Price Signal Algorithm Playground Algorithm Config
}

/**
 * Caeful: react-router-dom has timing issues if multiple searchParams are updated independently shortly after another
 * if you need to update multiple searchParams at once, consider using useGlobalParam instead
 * @param key name of the searchParam
 * @returns
 */
export const useSearchParam = (key: GlobalParamEnum) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSearchParamsChange = useCallback(
    (value?: string | number | boolean | null) => {
      let params: URLSearchParams = searchParams;
      setSearchParams(previous => {
        const newParams = new URLSearchParams(previous);
        newParams.set(key, isNil(value) ? '' : value.toString());
        params = newParams;
        return newParams;
      });
      return params;
    },
    [key, setSearchParams, searchParams],
  );
  return [searchParams.get(key) ?? '', handleSearchParamsChange] as const;
};

const getIsUrlExposed = (expose: SubmittedParamExposeType) =>
  [SubmittedParamExposeType.URL, SubmittedParamExposeType.URL_JSON].includes(
    expose,
  );

export const useGlobalParam = <
  T extends string | number | boolean | object,
  RT extends T | undefined = T,
>(
  key: GlobalParamEnum,
  {
    expose = SubmittedParamExposeType.NONE,
    replaceUrlHistory = false,
    initial,
  }: {
    expose?: T extends object
      ? Exclude<SubmittedParamExposeType, SubmittedParamExposeType.URL>
      : SubmittedParamExposeType;
    replaceUrlHistory?: boolean;
    initial?: T;
    storeJson?: boolean;
  } = {},
) => {
  const dispatch = useDispatch();
  const storedValue = useSelector<RootState, T | null>(
    state => selectSharedInputValue(state, key) as T | null,
  );
  const setStored = useCallback(
    (value: T) => dispatch(setSharedInputValue({ key, value })),
    [key, dispatch],
  );

  const submittedValue = useSelector<RootState, T | null>(
    state => selectSubmittedInputValue(state, key) as T | null,
  );
  const setSubmitted = useCallback(
    (value: T, expose: SubmittedParamExposeType) =>
      dispatch(setSubmittedInputValue({ key, value, expose })),
    [key, dispatch],
  );

  // update the searchParams with the new submitted values
  const [searchParams, setSearchParams] = useSearchParams();

  const submitted = useSelector(selectSubmittedInput);
  // submit the value to the store
  const submit = useCallback(
    (newValue: T) => {
      setStored(newValue);
      setSubmitted(newValue, expose);
      // return all submitted values, including the update
      const newSubmitted = {
        ...submitted,
        [key]: {
          value: newValue,
          expose,
        },
      };
      // return the search params, including the updated value
      const newSearchParams = new URLSearchParams(
        Object.entries(newSubmitted)
          .filter(([key, { expose }]) => getIsUrlExposed(expose))
          .reduce(
            (acc, [key, { value }]) => ({
              ...acc,
              [key]:
                expose === SubmittedParamExposeType.URL_JSON
                  ? btoa(JSON.stringify(newValue))
                  : value,
            }),
            {},
          ),
      );
      if (getIsUrlExposed(expose)) {
        setSearchParams(
          prev => {
            if (expose === SubmittedParamExposeType.URL_JSON) {
              prev.set(key, btoa(JSON.stringify(newValue)));
            } else {
              prev.set(key, `${newValue}`);
            }
            return prev;
          },
          {
            preventScrollReset: true,
            replace: replaceUrlHistory,
          },
        );
      }
      return [newSearchParams, newSubmitted] as const;
    },
    [
      key,
      expose,
      submitted,
      setStored,
      setSubmitted,
      setSearchParams,
      replaceUrlHistory,
    ],
  );

  const searchParamsValue = useMemo(
    () => searchParams.get(key),
    [searchParams, key],
  );
  const searchParam = useMemo(() => {
    if (expose === SubmittedParamExposeType.URL_JSON && searchParamsValue) {
      try {
        // convert from base64
        return JSON.parse(atob(searchParamsValue)) as T;
      } catch (e) {
        return undefined;
      }
    } else if (expose === SubmittedParamExposeType.URL) {
      return searchParamsValue as T;
    } else {
      return undefined;
    }
  }, [expose, searchParamsValue]);

  return [
    storedValue ?? submittedValue ?? searchParam ?? (initial as RT),
    submittedValue ?? searchParam ?? (initial as RT),
    setStored,
    submit,
  ] as const;
};

export const useBreakpoint = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null as Breakpoint | null) || 'xs'
  );
};
