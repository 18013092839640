import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '.';

export const selectAuth = (state: RootState) => state.auth;
export const selectAccessToken = createSelector(
  selectAuth,
  auth => auth.accessToken ?? null,
);
export const selectShouldShowLmLogin = createSelector(
  selectAuth,
  auth => auth.shouldShowLmLogin,
);

export const selectServicePortal = (state: RootState) => state.servicePortal;

export const selectSharedInput = createSelector(
  selectServicePortal,
  servicePortal => servicePortal.sharedInput,
);

export const selectSharedInputValue = createSelector(
  selectSharedInput,
  (_: RootState, key: string) => key,
  (sharedInput, key) => sharedInput[key] ?? null,
);

export const selectSubmittedInput = createSelector(
  selectServicePortal,
  servicePortal => servicePortal.submittedInput,
);

export const selectSubmittedInputValue = createSelector(
  selectSubmittedInput,
  (_: RootState, key: string) => key,
  (submittedInput, key) => submittedInput[key]?.value ?? null,
);
